<template>
  <div>
    <el-button @click="startExport" :loading="loading" type="warning"
      >导出</el-button
    >

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div>
        <slot>
            全部导出
        </slot>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: {
    tableName: {
      type: String,
      default: "海马运动导出表格",
    },
    values: {
      type: Array,
      default: [],
    },
    titles: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      loadingInstance: "",
      DataList: [],
      loading: false,
    };
  },

  mounted() {},

  methods: {
    confirm() {
      this.dialogVisible = false;
      this.$emit("startExport", true);
    },
    cancel() {
      this.dialogVisible = false;
    },
    //导出数据
    exportCodeAll() {
      if (this.DataList.length == 0) {
        this.$message("数据为空，无法导出");
        return;
      }
      this.ganareExcel();
    },
    //生成表格
    ganareExcel() {
      this.loading = true;
      try {
        // 需要导出的数据
        let exportArr = this.DataList;
        //   data.forEach((arr) => {
        //     exportArr = [...exportArr, ...arr];
        //   });

        const filterVal = this.values;
        const Header = [this.titles];

        exportArr = exportArr.map((item) => filterVal.map((v) => item[v]));

        // 自定义下载的header，注意是数组中的数组哦

        // 将JS数据数组转换为工作表。
        const headerWs = XLSX.utils.aoa_to_sheet(Header);
        const ws = XLSX.utils.sheet_add_json(headerWs, exportArr, {
          skipHeader: true,
          origin: "A2",
        });
        //设置表格的宽度
        ws["!cols"] = [
          {
            wpx: 100,
          },
          {
            wpx: 100,
          },
          {
            wpx: 100,
          },
          {
            wpx: 100,
          },
          {
            wpx: 100,
          },
        ];
        /* 新建空的工作表 */
        const wb = XLSX.utils.book_new();

        // 可以自定义下载之后的sheetname
        XLSX.utils.book_append_sheet(wb, ws, "sheetName");

        /* 生成xlsx文件 */
        XLSX.writeFile(wb, this.excelName);
        this.loading = false;
        this.$message("导出成功，请前往下载列表查看");
      } catch (error) {
        this.loading = false;
      }
      this.loading = false;
    },
    //
    importExcel(data) {
      this.DataList = data;
      this.excelName = `${this.tableName}.xlsx`;
      this.exportCodeAll();
    },
    startExport() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>