export const rules ={
  mechanism_name: [
    { required: true, message: "请填写机构名称", trigger: ["blur","change"] },
  ],
 
  mechanism_telephone: [
      { required: true, message: "请填写机构电话", trigger: ["blur","change"] },
    ],
    mechanism_addr: [
      { required: true, message: "请填写机构地址", trigger: ["blur","change"] },
    ],
    longitude: [
      { required: true, message: "请填写经度", trigger: "change" },
    ],
    latitude: [
      { required: true, message: "请填写纬度", trigger: "change" },
    ]
  }