<template>
  <div>
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      title="收益分成"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-button type="primary" icon="el-icon-plus" @click="addItem"
        >添加分成人员</el-button
      >
      <div v-for="(bonus, index) in list" :key="index">
        <div class="flex al" v-show="!bonus.del">
          <div class="label">分成人员</div>
          <admin-select
            @change="changeAdmin($event, index)"
            :condition="{is_employees:1}"
            :info="bonus.name"
          ></admin-select>
          <div class="label">分成比例</div>
          <el-input
            v-model="bonus.proportion"
            placeholder="如0.2，0.5"
            @change="checkProportion(index)"
            :key="index + 'i'"
          ></el-input>
          <el-button-group style="margin-left: 20px">
            <el-button type="primary" @click="saveItem(bonus)">保存</el-button>
            <el-button type="danger" @click="deleteItem(index)">删除</el-button>
          </el-button-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import adminSelect from "../select/adminSelect.vue";

export default {
  components: {
    adminSelect,
  },
  props: {
    mecid: {
      type: [String, Number],
      default: 0,
    },
  },
  watch: {
    mecid: {
      immediate: true,
      handler(nv, ov) {
        this.list = [];
        if (nv) {
          this.getMecBonusList();
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
    };
  },

  mounted() {},

  methods: {
    checkProportion(i){
        let pro = this.list[i].proportion
        if(pro>1){
            this.$message("分成比例最多为1")
            this.list[i].proportion = 1
        }
    },
    getMecBonusList() {
      this.$axios({
        url: "/user/adminBonus/queryMechanismBonus",
        params: {
          id: this.mecid,
        },
      }).then((res) => {
        res.data.data.forEach((item) => {
          item.del = 0;
        });
        this.list = res.data.data;
      });
    },
    saveItem(bonus) {
      bonus.id ? this.update(bonus) : this.save(bonus);
    },
    update(form) {
      this.$axios({
        url: "/user/adminBonus/update",
        method: "post",
        data: {
          id: form.id,
          admin_id: form.admin_id,
          name: form.name,
          proportion: form.proportion,
        },
      }).then((res) => {
        this.$message("保存成功");
      });
    },
    save(form) {
      for(let i = 0;i < this.list.length;i++){
        if(form.admin_id == this.list[i].admin_id){
          this.$message("该员工已配置分成，请勿重复添加")
          return
        }
      }
      form.mechanism_id = this.mecid;
      this.$axios({
        url: "/user/adminBonus/insert",
        method: "post",
        data: form,
      }).then((res) => {
        this.$message("保存成功");
      });
    },
    changeAdmin(admin, i) {
      this.list[i].admin_id = admin.id;
      this.list[i].name = admin.name;
    },

    open() {
      this.dialogVisible = true;
    },
    deleteItem(index) {
      if (this.list[index].id) {
        this.$confirm(`是否删除${this.list[index].name}的收益分成`).then(() => {
          this.$axios({
            url: "/user/adminBonus/delete",
            params: { id: this.list[index].id },
          }).then((res) => {
            this.list[index].del = 1;
          });
        });
      } else {
        this.list[index].del = 1;
      }
    },
    addItem() {
      this.list.push({ admin_id: "", proportion: "", del: 0 });
    },
  },
};
</script>

<style scoped>
.label {
  margin: 0 20px;
  line-height: 40px;
}
</style>