<template>
  <div>
    <el-dialog title="新增教练" :visible.sync="addDialogVisible" width="60%">
      <el-form
        class="demo-form-inline"
        :model="coachInfo"
        label-width="120px"
        :rules="rules"
        ref="form"
      >
        <div class="flex">
          <div class="col">
            <el-form-item label="头像" required prop="head_portrait">
              <avatar-uploader
                style="border-radius: 50%"
                @getImgUrl="getImgUrl"
                :url="coachInfo.head_portrait"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item label="姓名" required prop="name">
              <el-input
                v-model="coachInfo.name"
                placeholder="请输入真实姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证" required prop="id_number">
              <el-input
                v-model="coachInfo.id_number"
                placeholder="请输入身份证"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" required prop="password">
              <el-input
                v-model="coachInfo.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别" required prop="sex">
              <el-radio v-model="coachInfo.sex" label="1">男</el-radio>
              <el-radio v-model="coachInfo.sex" label="2">女</el-radio>
            </el-form-item>
            <el-form-item label="资格证等级证书">
              <el-button type="warning" @click="classShow = true"
                >添加资质</el-button
              >
              <div class="flexH" style="margin-top: 20px">
                <div
                  v-for="(item, index) in certificate_pic"
                  :key="index"
                  class="honor-box"
                >
                  <img :src="item" class="img" alt="" />
                  <p style="margin-top: 10px">
                    {{ class_certificate[index] }}
                  </p>
                  <div class="honor-tb" sy>
                    <i
                      class="el-icon-circle-close"
                      @click="delClassItem(index)"
                      style="font-size: 30px; cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </el-form-item>
            <!-- <el-form-item label="教练资格证" required prop="certificate_pic">
                <avatar-uploader
                  style="border-radius: 50%"
                  @getImgUrl="getGradImgUrl"
                  :url="coachInfo.certificate_pic"
                ></avatar-uploader>
              </el-form-item> -->
          </div>
          <!-- 右 -->
          <div class="col">
            <el-form-item label="教龄(年)" required prop="teaching_age">
              <el-input
                v-model="coachInfo.teaching_age"
                type="number"
                placeholder="请输入教龄"
              ></el-input>
            </el-form-item>
            <el-form-item label="授课类别(多选)" required>
              <el-select
                v-model="teaching_categories"
                filterable
                placeholder="请选择"
                multiple
                :multiple-limit="3"
              >
                <el-option
                  v-for="item in category"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="授课方式(多选)" required>
              <el-checkbox-group v-model="class_type">
                <el-checkbox label="3">到店上课</el-checkbox>
                <el-checkbox label="2">上门教学</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="教练地址" required key="city">
              <div class="flex">
                <addr-select @getform="addMecAddr"></addr-select>
                <el-button @click="useMecAddress">使用机构地址</el-button>
              </div>
            </el-form-item>
            <el-form-item label="城市" required>
              <el-cascader
                :options="city"
                v-model="address"
                :placeholder="address.join('/')"
                :props="{
                  children: 'childrens',
                  label: 'district',
                  multiple: false,
                  value: 'district',
                }"
                @change="handleChangeCity"
                filterable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" required>
              <el-input
                v-model="coachInfo.address"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>

            <el-form-item label="服务人群(多选)" required>
              <el-select
                v-model="service_person"
                placeholder="请选择服务人群"
                multiple
              >
                <el-option
                  v-for="item in crowd"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="上门服务范围"
              required
              v-if="class_type.includes('2')"
            >
              <el-select
                v-model="coachInfo.distance_service"
                placeholder="请选择服务距离"
              >
                <el-option
                  v-for="item in Range"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教练介绍">
              <el-input
                v-model="coachInfo.self_introduction"
                placeholder="教练介绍"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="荣耀证书">
              <el-button type="warning" @click="honoropen = true"
                >添加荣誉资质</el-button
              >
              <div class="flexH" style="margin-top: 20px">
                <div
                  v-for="(item, index) in honor_qualification_imgs"
                  :key="index"
                  class="honor-box"
                >
                  <img :src="item" class="img" alt="" />
                  <p style="margin-top: 10px">
                    {{ honor_qualification[index] }}
                  </p>
                  <div class="honor-tb" sy>
                    <i
                      class="el-icon-circle-close"
                      @click="honoreliminate(index)"
                      style="font-size: 30px; cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm" :loading="isSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog title="荣誉资质" :visible.sync="honoropen" width="500px">
      <el-form label-position="left" label-width="80px">
        <el-form-item label="荣誉资质" required>
          <el-input placeholder="证书名称" v-model="Honorarynote"> </el-input>
        </el-form-item>
        <el-form-item label="相关照片" required v-if="honoropen">
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="Honorimg"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="honormodify">添加</el-button>
          <el-button type="danger" @click="honoropen = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--  -->
    <el-dialog title="资格等级证书" :visible.sync="classShow" width="500px">
      <el-form label-position="left" label-width="80px">
        <el-form-item label="资格等级" required>
          <el-select v-model="temp_class" placeholder="请选择">
            <el-option
              v-for="item in Grade"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书照片" required>
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="upLoadTempClass"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="addClassItem">添加</el-button>
          <el-button type="danger" @click="classShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  
  <script>
import { rules } from "./rules.js";
export default {
  props: ["phone", "select_mechanism"],
  data() {
    return {
      isSubmit: false,
      addDialogVisible: false,
      classShow: false,
      honoropen: false,
      honorimgs: "",
      Honorarynote: "",
      honor_qualification_imgs: [],
      honor_qualification: [],

      //等级证书
      class_certificate: [],
      certificate_pic: [],
      temp_class: "",
      temp_class_pic: "",
      category: [],
      user: {},
      coachInfo: {},
      address: [],
      city: JSON.parse(localStorage.getItem("addrList")),
      class_type: [],
      teaching_categories: [],
      Range: [],
      Grade: [
        {
          id: "1",
          name: "初级",
        },
        {
          id: "2",
          name: "中级",
        },
        {
          id: " 3",
          name: "高级",
        },
        {
          id: "4",
          name: "特级",
        },
      ],
      service_person: [], //服务人群
      crowd: [
        {
          name: "成人",
          id: "1",
        },
        {
          name: "学龄前",
          id: "2",
        },
        {
          name: "学龄",
          id: "3",
        },
        {
          name: "亲子",
          id: "4",
        },
      ],
      rules: rules,
      coach_id: "",
    };
  },

  mounted() {
    this.$get("user/mechanismCategory/queryListPageChild", {
      status: 2,
      type: 2,
      source: "体育宝",
    }).then((res) => {
      this.category = res.data.data;
    });
    for (let i = 0; i <= 50; i += 5) {
      this.Range.push({
        value: i,
        lable: i == 0 ? "全城" : i + "km",
      });
    }
  },

  methods: {
    delClassItem(i) {
      this.class_certificate.splice(i, 1);
      this.certificate_pic.splice(i, 1);
      this.classShow = false;
    },
    addClassItem() {
      this.class_certificate.push(this.temp_class);
      this.certificate_pic.push(this.temp_class_pic);
      this.classShow = false;
    },
    upLoadTempClass(v) {
      this.temp_class_pic = v;
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addNewCoach();
        } else {
          return false;
        }
      });
    },
    addNewCoach() {
      this.isSubmit = true;
      let data = {
        name: this.coachInfo.name,
        sex: this.coachInfo.sex,
        password: this.coachInfo.password,
        self_introduction: this.coachInfo.self_introduction,
        head_portrait: this.coachInfo.head_portrait,
        phone: this.phone,
        teaching_type: this.class_type.join(","),
        people_service: this.service_person.join(","),
        distance_service: this.coachInfo.distance_service,
        province: this.coachInfo.province,
        city: this.coachInfo.city,
        district: this.coachInfo.district,
        address: this.coachInfo.address,
        latitude: this.coachInfo.latitude,
        longitude: this.coachInfo.longitude,
        mechanism_ids: this.select_mechanism,
        real_name: this.coachInfo.name,
        id_number: this.coachInfo.id_number,
        teaching_age: this.coachInfo.teaching_age,
        class_certificate: this.class_certificate.join(","),
        certificate_pic: this.certificate_pic.join(","),
        teaching_categories: this.teaching_categories.join(","),
        honor_qualification: this.honor_qualification.join(","),
        honor_qualification_imgs: this.honor_qualification_imgs.join(","),
      };
      this.$axios
        .post("user/coach/mechanism/insert", data)
        .then((res) => {
          this.$message(res.data.message)
          if (res.data.code == 0) {
            this.coach_id = res.data.data;
            this.addDialogVisible = false
          } else {
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
        });
    },

    // 荣耀证书
    honoreliminate(e) {
      this.honor_qualification_imgs.splice(e, 1);
      this.honor_qualification.splice(e, 1);
    },
    honormodify() {
      if (this.honorimgs && this.Honorarynote) {
        this.honor_qualification_imgs.push(this.honorimgs);
        this.honor_qualification.push(this.Honorarynote);
        this.honor_qualification_imgs = [...this.honor_qualification_imgs];
        this.honor_qualification = [...this.honor_qualification];
        this.honoropen = false;
        this.honorimgs = "";
        this.Honorarynote = "";
      }
    },
    // 选择城市
    handleChangeCity(e) {
      this.coachInfo.province = e[0];
      this.coachInfo.city = e[1];
      this.coachInfo.district = e[2];
    },
    //    地址
    addMecAddr(addr) {
      this.address = [];
      this.$set(this.coachInfo, "province", addr.province);
      this.$set(this.coachInfo, "city", addr.city);
      this.$set(this.coachInfo, "district", addr.district);
      this.$set(this.coachInfo, "latitude", addr.addrPoint.lat);
      this.$set(this.coachInfo, "longitude", addr.addrPoint.lng);
      this.$set(this.coachInfo, "address", addr.address);
      this.address.push(addr.province);
      this.address.push(addr.city);
      this.address.push(addr.district);
    },
    useMecAddress() {
      let addr = JSON.parse(localStorage.getItem("mechanism_info"));
      this.address = [];
      this.$set(this.coachInfo, "province", addr.province);
      this.$set(this.coachInfo, "city", addr.city);
      this.$set(this.coachInfo, "district", addr.district);
      this.$set(this.coachInfo, "latitude", addr.latitude);
      this.$set(this.coachInfo, "longitude", addr.longitude);
      this.$set(this.coachInfo, "address", addr.address);
      this.address.push(addr.province);
      this.address.push(addr.city);
      this.address.push(addr.district);
    },
    // 图片
    getImgUrl(e) {
      this.coachInfo.head_portrait = e;
    },
    getGradImgUrl(e) {
      this.coachInfo.certificate_pic = e;
    },
    Honorimg(e) {
      this.honorimgs = e;
    },

    display(v) {
      this.addDialogVisible = v;
    },
  },
};
</script>
  
  <style >
.col {
  min-width: 45%;
  display: flex;
  flex-direction: column;
}

.honor-box {
  width: 100px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.honor-tb {
  position: absolute;
  top: -10px;
  right: -10px;
}

.honor-box > .img {
  width: 100px;
  height: 100px;
}
.honor-box > p {
  height: 1em;
  line-height: 1;
}
</style>